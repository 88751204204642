
  import Vue from 'vue'
  import { mapActions } from 'vuex'
  import { ethers } from 'ethers' 
  import { EcosystemId, IEcosystem } from '@/ecosystem'

  // @ts-ignore:disable-next-line
  import VueCryptojs from 'vue-cryptojs';
  Vue.use(VueCryptojs)

  import { ERC20_ABI, LAUNCHPAD_FACTORY_ABI, LAUNCHPAD_PRESALE_ABI, ZERO_ADDRESS } from '@/constants'
  import { ChainId } from '@/ecosystem'
  import { delay, equalsInsensitive } from '@/utils'
  import { PresaleData } from '@/types'


  export default Vue.extend ({
    data: () => ({

      valid: true,
      depositFormValid: <boolean> false,
      presaleAddress: <string> '',
      isPresaleValid: <boolean> false,
      hostname: <string> '',

      // Data pulled from the contract
      tokenName: null,
      tokenSymbol: null,
      tokenAddress: <string> '',
      tokenSupply: <ethers.BigNumber | null> null,
      tokenDecimals: <number | null> null,
      presaleHardCap: <ethers.BigNumber | null> null,
      presaleSoftCap: <ethers.BigNumber | null> null,
      presaleTokensPerEth: <ethers.BigNumber | null> null,
      presaleEndTime: <number> 0, // Stored as a UNIX timestamp in miliseconds
      maxContribution: <ethers.BigNumber | null> null,

      yourContribution: <ethers.BigNumber | null> null, // Amount already contributed by this wallet
      boughtTokens: <ethers.BigNumber | null> null, // Amount of tokens to be received for contribution
      referralsEnabled: <boolean | null> null,
      referralEarned: <ethers.BigNumber | null> null, // Amount of money earned from your referral link
      referrerAddress: <string | null> null,

      presaleIsActive: <boolean | null> null,
      presaleIsAborted: <boolean | null> null,
      dplpFarm: <string | null> ZERO_ADDRESS,

      // Checking if any warnings should be displayed
      customTokenWarning: <boolean> false,

      // User-entered data (from the .json string in the contract)
      presaleInfo: <string | null> null,
      // Updated in real time
      presaleRaised: 43,
      timeLeft: 0,

      // User-entered data
      amountToDeposit: '0',
      tokensGiven: 0,
      active: true,

      // Admin area
      isPresaleOwner: <boolean | null> null,
      cancelFormValid: <boolean> false,
      cancelPresaleRules: [
        (v: any) => (v == 'CANCEL') || 'Please type CANCEL to confirm'
      ],

      // Editing token info
      editLogoUrl: <string | null> null,
      editWebsiteUrl: <string | null> null,
      editTelegramUrl: <string | null> null,
      editDiscordUrl: <string | null> null,
      editPublic: <boolean> true,
      websiteUrlRules: [
          (v: any) => (!v || /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/.test(v)) || 'Enter a valid website URL, or leave empty if you don\'t have one'
        ],
      logoUrlRules: [
          (v: any) => (!v || /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/.test(v)) || 'Enter a valid link to your logo, or leave empty if you don\'t have one'
        ],
      telegramUrlRules: [
          (v: any) => (!v || /(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?$/.test(v)) || 'Enter a valid Telegram invite link, or leave empty if you don\'t have one'
        ],
      discordUrlRules: [
          (v: any) => (!v || /(https?:\/\/)?(www[.])?discord\.(com\/invite|gg)\/([a-zA-Z0-9_-]*)\/?$/.test(v)) || 'Enter a valid Discord invite link, or leave empty if you don\'t have one'
      ],


      ZERO_ADDRESS
    }),
    created() {
      window.onstorage = () => {
        this.$store.commit('setUserProfile')
      };
      this.presaleAddress = this.$route.params.address
      this.referrerAddress = this.decrypt(this.$route.query.r?.toString())
      this.hostname = window.location.host
    },
    async mounted() {
      setInterval(() => {
        this.timeLeft = this.presaleEndTime - Date.now()
      }, 1000)

      while (this.active) {
        try {
          await this.sync()
        } catch (e) {
          console.error(e)
        }

        await delay(5000)
      }
    },
    beforeDestroy() {
      this.active = false
    },
    beforeRouteLeave(to, from, next) {
      this.active = false
      next()
    },
    computed: {
      suppliedTokenStyle(): string {
        let style = "font-weight: bold;"
        let tokenPercentage = ((parseFloat(this.displayedSale.presaleTokenAmount) * 1.72) / this.displayedSale.tokenSupply) * 100
        if (tokenPercentage > 80) {
          style += "color: #00f3ff;"
        }
        else if (tokenPercentage >= 50) {
          style += "color: #85ff00;"
        }
        else if (tokenPercentage >= 15) {
          style += "color: #ffbc00;"
        }
        else {
          style += "color: #ff5e00;"
        }
        return style
      },
      ecosystemId: {
        get(): EcosystemId {
          return this.$store.state.ecosystemId
        },
        set(val: EcosystemId) {
          this.$store.commit('setEcosystemId', val)
        }
      },
      ecosystem(): IEcosystem {
        return this.$store.getters.ecosystem
      },
      timeLeftInSeconds: function (): number {
        return Math.trunc(this.timeLeft / 1000)
      },
      secondsLeft: function (): number {
        return this.timeLeftInSeconds % 60
      },
      minutesLeft: function (): number {
        return Math.trunc(this.timeLeftInSeconds / 60) % 60
      },
      hoursLeft: function (): number {
        return (Math.trunc((this.timeLeftInSeconds / 60) / 60))
      },
      tokenLogo: function(): string {
          return '@/assets/icons/LaunchPAD Icon.svg'
      },
      presaleContract(): ethers.Contract | null {
        if (!ethers.utils.isAddress(this.presaleAddress)) {
          return null
        }

        return new ethers.Contract(this.presaleAddress, LAUNCHPAD_PRESALE_ABI, this.multicall)
      },
      presaleContractSigner(): ethers.Contract | null {
        if (!this.presaleContract || !this.web3) {
          return null
        }
        return this.presaleContract.connect(this.web3)
      },
      factoryContract(): ethers.Contract {
        return new ethers.Contract(this.$store.getters.ecosystem.launchPadFactoryAddress, LAUNCHPAD_FACTORY_ABI, this.multicall)
      },
      factoryContractSigner(): ethers.Contract | null {
        if (!this.web3) {
          return null
        }
        return this.factoryContract.connect(this.web3)
      },
      multicall(): ethers.providers.Provider {
        return this.$store.getters.multicall
      },
      presaleTokenAmount(): ethers.BigNumber {
        if (!this.presaleTokensPerEth || !this.presaleHardCap) {
          return ethers.BigNumber.from(0)
        }

        const presaleTokenAmountEther = this.presaleTokensPerEth.mul(this.presaleHardCap)
        return presaleTokenAmountEther
      },
      displayedSale(): any {
        return {
          tokenName: this.tokenName,
          tokenSymbol: this.tokenSymbol,
          tokenSupply: this.tokenSupply ? ethers.utils.formatUnits(this.tokenSupply, this.tokenDecimals!) : null,
          presaleHardCap: this.presaleHardCap ? ethers.utils.formatEther(this.presaleHardCap) : null,
          presaleSoftCap: this.presaleSoftCap ? ethers.utils.formatEther(this.presaleSoftCap) : null,
          presaleTokensPerEth: this.presaleTokensPerEth?.toNumber(),
          presaleEndTime: this.presaleEndTime * 1000,
          maxContribution: this.maxContribution ? ethers.utils.formatEther(this.maxContribution) : null,
          presaleTokenAmount: this.presaleTokensPerEth ? this.presaleTokensPerEth.toNumber() * parseFloat(ethers.utils.formatEther(this.presaleHardCap!)) : null,
          presaleRaised: this.presaleRaised ? ethers.utils.formatEther(this.presaleRaised) : null,
          yourContribution: this.yourContribution ? ethers.utils.formatEther(this.yourContribution) : null,
          boughtTokens: this.boughtTokens ? ethers.utils.formatUnits(this.boughtTokens, this.tokenDecimals!) : null,
          referralEarned: this.referralEarned ? ethers.utils.formatEther(this.referralEarned) : null,
          presaleInfo: this.presaleInfo ?  this.stringToObject(this.presaleInfo) : {
            tokenLogoUrl: '',
            telegramUrl: '',
            discordUrl: '',
            websiteUrl: '',
            isPublic: true
          },
        }
      },
      presaleCurrency(): string {
        return this.$store.getters.ecosystem.ethName
      },
      presaleIsCompleted(): boolean {
        return this.presaleIsActive === false && this.presaleIsAborted === false
      },
      farmLink(): string | null {
        if (!this.dplpFarm) {
          return null
        }

        return `http://${this.hostname}/${this.$store.getters.ecosystem.routeName}/farms?import=${this.dplpFarm}`
      },
      isPresaleLoading(): boolean {
        return this.tokenName === null
      },
      web3(): ethers.Signer | null {
        return this.$store.state.web3
      },
      address(): string | null {
        return this.$store.state.address
      },
      chainId(): ChainId {
        return this.$store.getters.ecosystem.chainId
      }
    },
    methods: {
      tokenContractLink() {
        let chainExplorerLinks : any = {
          'bsc': 'https://bscscan.com/token/',
          'moonriver': 'https://moonriver.moonscan.io/token/',
          'moonbeam': 'https://moonbeam.moonscan.io/token/'
        }
        let chainExplorerNames : any = {
          'bsc': 'BSCscan',
          'moonriver': 'Moonscan',
          'moonbeam': 'Moonscan'
        }
        let chain = this.$store.getters.ecosystem.routeName
        let explorerLink = chainExplorerLinks[chain]
        let explorerName = chainExplorerNames[chain]
        return '<a href="' + explorerLink + this.tokenAddress + '" target="_blank">View on ' + explorerName +'</a>'
      },
      presaleImported() {
        const importedPresales = this.$store.state.userProfile.importedPresales[this.ecosystemId]

        const existingEntry = importedPresales.find((f: PresaleData) => equalsInsensitive(f.presaleLink, this.$route.path))
        if (existingEntry) {
          return true
        }
        return false
      },
      importPresale() {
        if (!this.displayedSale.tokenName || !this.displayedSale.tokenName) {
          return
        }

        const userProfileSerialized = localStorage.getItem(this.$store.state.USER_PROFILE_KEY)
        if (userProfileSerialized) {
          const savedUserProfile = JSON.parse(userProfileSerialized)
          Object.assign(this.$store.state.userProfile, this.$store.state.savedUserProfile)
        }

        const presaleConfig = {
          logo: this.displayedSale.presaleInfo.tokenLogoUrl,
          name: this.displayedSale.tokenName,
          ticker: this.displayedSale.tokenSymbol,
          presaleLink: this.$route.path
        }

        const importedPresales = this.$store.state.userProfile.importedPresales[this.ecosystemId]

        const existingEntry = importedPresales.find((f: PresaleData) => equalsInsensitive(f.presaleLink, presaleConfig.presaleLink))
        if (existingEntry) {
          const id = importedPresales.indexOf(existingEntry)
          if (id > -1) {
            importedPresales.splice(id, 1);
           }
        }

        this.$store.state.userProfile.importedPresales[this.ecosystemId].push(presaleConfig)

        setTimeout(() => this.sync())
      },
      removePresale() {
        const importedPresales = this.$store.state.userProfile.importedPresales[this.ecosystemId]

        const existingEntry = importedPresales.find((f: PresaleData) => equalsInsensitive(f.presaleLink, this.$route.path))
        if (existingEntry) {
          const id = importedPresales.indexOf(existingEntry)
          if (id > -1) {
            importedPresales.splice(id, 1);
           }
        }

        setTimeout(() => this.sync())
      },
      formatNumberWithCommas(nbr : any) {
        if (!nbr) {
          return '0.0'
        }
        return nbr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      getReferralLink() {
        return 'http://' + this.hostname + this.$route.path + '?r=' + this.encrypt(this.$store.state.address)
      },
      encrypt(text : string) : string {
        if (!text) {
          return ''
        }

        const iv = (Vue as any).CryptoJS.enc.Utf8.parse('586E3272357538782F413F4428472B4B')
        const key = (Vue as any).CryptoJS.enc.Utf8.parse('7336763979244226452948' + this.presaleAddress.slice(10, 20).toString())

        var encrypted = (Vue as any).CryptoJS.AES.encrypt(text, key, { iv: iv });
        var encryptedText = encrypted.toString()

        return this.toUrlSafe(encryptedText)
      },
      decrypt(text : string) : string {
        if (!text) {
          return ''
        }

        text = this.fromUrlSafe(text)

        const iv = (Vue as any).CryptoJS.enc.Utf8.parse('586E3272357538782F413F4428472B4B')
        const key = (Vue as any).CryptoJS.enc.Utf8.parse('7336763979244226452948' + this.presaleAddress.slice(10, 20).toString())

        var cipherParams = (Vue as any).CryptoJS.lib.CipherParams.create({
          ciphertext: (Vue as any).CryptoJS.enc.Base64.parse(text)
        });

        var decrypted = (Vue as any).CryptoJS.AES.decrypt(cipherParams, key, { iv: iv});
        var decryptedText = decrypted.toString((Vue as any).CryptoJS.enc.Utf8)
        decryptedText = decryptedText.slice(0, 42)

        return decryptedText
      },
      toUrlSafe(base64text : string) {
        base64text = base64text.replaceAll('+', '-')
        base64text = base64text.replaceAll('/', '_')
        base64text = base64text.replaceAll('=', '~')
        return base64text
      },
      fromUrlSafe(base64text : string) {
        base64text = base64text.replaceAll('-', '+')
        base64text = base64text.replaceAll('_', '/')
        base64text = base64text.replaceAll('~', '=')
        return base64text
      },
      validDepositAmount() {
        if (parseFloat(this.amountToDeposit) <= 0 || this.amountToDeposit == '') {
          return ['Please enter the amount you wish to deposit']
        }
        return [true]
      },
      async goToFarm() {
        if (!this.dplpFarm) {
          return
        }
        this.$router.push({
          path: `/${this.$store.getters.ecosystem.routeName}/farms`,
          query: { import: this.dplpFarm}
        })
      },
      async cancelPresale() {
        const tx = await this.factoryContractSigner!.populateTransaction.abortPresale(this.presaleAddress)
        await this.safeSendTransaction({ tx, targetChainId: this.chainId })
      },
      async updateTokenInfo() {
        const presaleInfo = JSON.stringify({
          tokenLogoUrl: this.editLogoUrl,
          websiteUrl: this.editWebsiteUrl,
          telegramUrl: this.editTelegramUrl,
          discordUrl: this.editDiscordUrl,
          isPublic: this.editPublic
        })
        const tx = await this.factoryContractSigner!.populateTransaction.changePresaleInfo(this.presaleAddress, presaleInfo)
        await this.safeSendTransaction({ tx, targetChainId: this.chainId })
      },
      async refund () {
        if (!this.web3) {
          await this.requestConnect()
          return
        }

        const tx = await this.presaleContractSigner!.populateTransaction.claimRefund()
        await this.safeSendTransaction({ tx, targetChainId: this.chainId })
      },
      async claimTokens () {
        if (!this.web3) {
          await this.requestConnect()
          return
        }

        const tx = await this.presaleContractSigner!.populateTransaction.claimTokens()
        await this.safeSendTransaction({ tx, targetChainId: this.chainId })
      },
      async claimReferralEarnings () {
        if (!this.web3) {
          await this.requestConnect()
          return
        }

        const tx = await this.presaleContractSigner!.populateTransaction.claimReferralBonus()
        await this.safeSendTransaction({ tx, targetChainId: this.chainId })
      },
      async deposit () {
        if (!this.presaleContract) {
          return
        }
        if (!this.web3) {
          await this.requestConnect()
          return
        }
        const amountWei = ethers.utils.parseEther(this.amountToDeposit)
        let referrerAddress = this.referrerAddress
        if (!referrerAddress ||
            !ethers.utils.isAddress(referrerAddress) ||
            equalsInsensitive(referrerAddress, this.address!)) {
          referrerAddress = ZERO_ADDRESS
        }

        const tx = await this.presaleContractSigner!.populateTransaction.buy(referrerAddress)
        tx.value = amountWei
        this.importPresale()
        await this.safeSendTransaction({ tx, targetChainId: this.chainId })
      },
      trimNumber (nbr: number) {
        let str_nbr = nbr.toString();
        return Number(str_nbr.slice(0, 3))
      },
      sanitizer(str: string) {
        let element = document.createElement('div');
        element.innerText = str;
        return element.innerHTML;
      },
      fixUrl(str: string) {
        if (!str.includes('http://') && !str.includes('https://') && str.length > 0) {
          str = 'https://' + str
        }
        return str
      },
      stringToObject (str: string) {
        const obj = {
          tokenLogoUrl: "",
          telegramUrl: "",
          discordUrl: "",
          websiteUrl: "",
          isPublic: true
        }
        try {
            const parsed = JSON.parse(str)
            obj.tokenLogoUrl = this.sanitizer(parsed.tokenLogoUrl)
            obj.telegramUrl = this.sanitizer(parsed.telegramUrl)
            obj.discordUrl = this.sanitizer(parsed.discordUrl)
            obj.websiteUrl = this.sanitizer(parsed.websiteUrl)
            obj.telegramUrl = this.fixUrl(obj.telegramUrl)
            obj.discordUrl = this.fixUrl(obj.discordUrl)
            obj.websiteUrl = this.fixUrl(obj.websiteUrl)
            if (parsed.isPublic != undefined) {
              obj.isPublic = parsed.isPublic
            }
        } catch (e) {
            return obj
        }
        return obj
      },
      async sync() {
        if (!this.presaleContract) {
          return
        }

        if (this.tokenName === null) {
          this.isPresaleValid = true
          const tokenAddress = <string> await this.presaleContract.token()
          this.tokenAddress = tokenAddress
          const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, this.multicall)

          const firstLoadData = {
            tokenName: <string | null> null,
            tokenSymbol: <string | null> null,
            tokenSupply: <ethers.BigNumber | null> null,
            tokenDecimals: <number | null> null,
            presaleSoftCap: <ethers.BigNumber | null> null,
            presaleHardCap: <ethers.BigNumber | null> null,
            presaleTokensPerEth: <ethers.BigNumber | null> null,
            presaleEndTime: <number | null> null,
            presaleInfo: <string | null> null,
            maxContribution: <ethers.BigNumber | null> null,
            referralsEnabled: <boolean | null> null,
            isPresaleOwner: <boolean | null> null
          }

          const promises = [
            tokenContract.name().then((n: string) => firstLoadData.tokenName = n),
            tokenContract.symbol().then((s: string) => firstLoadData.tokenSymbol = s),
            tokenContract.totalSupply().then((s: ethers.BigNumber) => firstLoadData.tokenSupply = s),
            tokenContract.decimals().then((d: number) => firstLoadData.tokenDecimals = d),
            this.presaleContract.softCap().then((s: ethers.BigNumber) => firstLoadData.presaleSoftCap = s),
            this.presaleContract.hardCap().then((h: ethers.BigNumber) => firstLoadData.presaleHardCap = h),
            this.presaleContract.tokensPerEth().then((t: ethers.BigNumber) => firstLoadData.presaleTokensPerEth = t),
            this.presaleContract.endsAt().then((e: ethers.BigNumber) => firstLoadData.presaleEndTime = e.toNumber() * 1000),
            this.presaleContract.presaleInfo().then((p: string) => firstLoadData.presaleInfo = p),
            this.presaleContract.buyLimit().then((b: ethers.BigNumber) => firstLoadData.maxContribution = b),
            this.presaleContract.referrals().then((b: boolean) => firstLoadData.referralsEnabled = b)
          ]
          await Promise.all(promises)
          Object.assign(this, firstLoadData)

          this.editLogoUrl = this.displayedSale.presaleInfo.tokenLogoUrl,
          this.editWebsiteUrl = this.displayedSale.presaleInfo.websiteUrl,
          this.editTelegramUrl = this.displayedSale.presaleInfo.telegramUrl,
          this.editDiscordUrl = this.displayedSale.presaleInfo.discordUrl,
          this.editPublic = this.displayedSale.presaleInfo.isPublic
        }

        let canBuy: boolean = false
        let canEnd: boolean = false
        const data: any = {
          presaleIsActive: <boolean | null> null,
          presaleIsAborted: <boolean | null> null,
          presaleRaised: <ethers.BigNumber | null> null,
          presaleInfo: <string | null> null,
          yourContribution: <ethers.BigNumber | null> null,
          boughtTokens: <ethers.BigNumber | null> null,
          referralEarned: <ethers.BigNumber | null> null,
          dplpFarm: <string | null> null,
        }

        const promises = [
          this.presaleContract.canBuy().then((c: boolean) => canBuy = c),
          this.presaleContract.canEnd().then((c: boolean) => canEnd = c),
          this.presaleContract.isAborted().then((a: boolean) => data.presaleIsAborted = a),
          this.presaleContract.presaleInfo().then((p: string) => data.presaleInfo = p),
          this.presaleContract.dplpFarm().then((d: string) => data.dplpFarm = d),
          this.multicall.getBalance(this.presaleContract.address).then((b: ethers.BigNumber) => data.presaleRaised = b)
        ]
        if (this.address) {
          promises.push(
            this.presaleContract.paidAmount(this.address).then((a: ethers.BigNumber) => data.yourContribution = a),
            this.presaleContract.boughtTokensOf(this.address).then((t: ethers.BigNumber) => data.boughtTokens = t),
            this.presaleContract.referralBonuses(this.address).then((b: ethers.BigNumber) => data.referralEarned = b),
            this.factoryContract.getPresaleOwner(this.presaleContract.address).then((o: string) => this.isPresaleOwner = equalsInsensitive(o, this.address!))
          )
        }
        await Promise.all(promises)

        data.presaleIsActive = canBuy && !canEnd
        if (data.dplpFarm && data.dplpFarm != ZERO_ADDRESS) {
          // workaround for contract returning isAborted = true after donating to dplp
          data.presaleIsAborted = false
        }
        Object.assign(this, data)
      },
      ...mapActions(['requestConnect', 'safeSendTransaction']),
      copyText (text : string) {
        let textArea = document.createElement("textarea")
        textArea.value = text
        textArea.style.top = "0"
        textArea.style.left = "0"
        textArea.style.position = "fixed"
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        let successful = document.execCommand('copy')

        document.body.removeChild(textArea)
      },
    },
    watch: {
      amountToDeposit () {
        this.tokensGiven = (parseFloat(this.displayedSale.presaleTokenAmount) / parseFloat(this.displayedSale.presaleHardCap)) * parseFloat(this.amountToDeposit)
      }
    }
  })
